<template>
  <v-row class="d-flex justify-center mt-0">
    <v-carousel
      v-model="model"
      prev-icon="fas fa-angle-left"
      next-icon="fas fa-angle-right"
      :height="$vuetify.breakpoint.smAndDown ? '80vh' : '90vh'"
      :show-arrows="arrow"
      :interval="12000"
      hide-delimiters
      cycle
    >
      <div @click="$vuetify.breakpoint.smAndDown ? arrow = !arrow : ''">
        <v-carousel-item
          v-for="(item, index) in $store.state.banners"
          :key="index"
          :src="`data:image/jpeg;base64,${item.img}`"
          contain
        ></v-carousel-item>
      </div>
    </v-carousel>
    <div class="my-2">
      <v-btn
        v-for="i in $store.state.banners.length"
        :key="i-1"
        icon
        @click="model = i-1"
      >
        <v-icon small>
          {{ i-1 === model ? 'fas' : 'far' }} fa-circle
        </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      model: 0,
      arrow: true,
    }
  }
}
</script>